import React from "react";
import { Home, About, Work, SingleWork, NotFound } from "./pages";
import { featuredImage } from "./utils/utils";
import Layout from "./Layout";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

const AnimRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home featuredImage={featuredImage} />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/work"
            element={<Work featuredImage={featuredImage} />}
          />
          <Route
            path="/work/:workName"
            element={<SingleWork featuredImage={featuredImage} />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
