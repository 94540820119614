import React from "react";
import { useEffect, useState } from "react";
import { WorkGrid, Loading } from "../../components";
import { motion } from "framer-motion";
import "./index.scss";

const Work = ({ featuredImage }) => {
  const restPath =
    "https://gabbiebade.com/XzgxVFWID/wp-json/wp/v2/pages/10?_embed";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  return (
    <motion.div
      className="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {isLoaded ? (
        <section id="work">
          <h2>{restData?.title?.rendered}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: restData?.content?.rendered }}
            className="work-intro"
          ></div>
          <div className="gallery">
            <WorkGrid featuredImage={featuredImage} />
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </motion.div>
  );
};

export default Work;
