import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header, Nav } from "./components";
import { motion } from "framer-motion";

const Layout = () => {
  return (
    <>
      <Header>
        <Nav />
      </Header>
      <motion.div
        initial={{ opacity: 0, y: 80 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.25 }}
        exit={{ opacity: 0 }}
      >
        <main className="main">
          <Outlet />
        </main>
      </motion.div>
      <Footer />
    </>
  );
};

export default Layout;
