import React from "react";
import "./index.scss";
import basePhoto from "../../media/base-photo.png";
import baseWebp from "../../media/base-photo.webp";
import maskWebp from "../../media/hair-mask.webp";
import hairMask from "../../media/hair-mask.png";
import { ReactComponent as ColorFill } from "../../media/color-fill-path.svg";
import { useState } from "react";

const MainPhotoStored = () => {
  const [hairdye, setHairdye] = useState("");

  const handleChange = (e) => {
    document.documentElement.style.setProperty("--color-input", e.target.value);
    localStorage.setItem("dye", e.target.value);
    setHairdye(localStorage.getItem("dye"));
  };

  const handleReset = (e) => {
    document.documentElement.style.setProperty("--color-input", hairdye);
    localStorage.removeItem("dye");
    setHairdye("#D400C9");
  };
  return (
    <section className="main-photo">
      <figure>
        <picture>
          <source srcSet={maskWebp} type="image/webp" />
          <img src={hairMask} alt="" role="presentation" id="mask" />
        </picture>
        <ColorFill id="colorFill" />
        <picture>
          <source srcSet={baseWebp} type="image/webp" />
          <img src={basePhoto} alt="Gabbie Bade" id="base" />
        </picture>
      </figure>
      <div className="colorInput">
        <input
          type="color"
          name="hairColor"
          id="hairColor"
          value={
            localStorage.getItem("dye")
              ? localStorage.getItem("dye")
              : "#D400C9"
          }
          onChange={handleChange}
        />
        <label htmlFor="hairColor">
          Change the colour and see how I might look like!
        </label>
        <button
          type="reset"
          onClick={handleReset}
          aria-label="reset color"
          id="reset"
          title="Reset color"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
          >
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.527 7.708c.741-.444 1.6-.708 2.527-.708 2.757 0 5 2.243 5 5h2c0-3.86-3.141-7-7-7-1.336 0-2.58.385-3.641 1.038l-1.359-2.038-1.719 6h5.719l-1.527-2.292zm5.054 8.584c-.741.444-1.6.708-2.527.708-2.757 0-5-2.243-5-5h-2c0 3.86 3.141 7 7 7 1.336 0 2.58-.385 3.641-1.038l1.359 2.038 1.719-6h-5.719l1.527 2.292z" />
          </svg>
          <title>Reset Hair Color</title>
        </button>
      </div>
    </section>
  );
};

export default MainPhotoStored;
