import { NavLink } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import { ReactComponent as SquiggleCont } from "../../media/squiggle.svg";
import { ReactComponent as Squiggle } from "../../media/squiggle_short.svg";
import "./index.scss";

const Nav = () => {
  const isMobile = useIsMobile();

  return (
    <nav id="nav" className={isMobile ? "mobile-nav" : "web-nav"}>
      <ul>
        <li>
          <NavLink to="/" end>
            Home
            {isMobile ? null: <Squiggle />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/about">
            About
            {isMobile ? null: <Squiggle />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/work">
            Work
            {isMobile ? null: <Squiggle />}
          </NavLink>
        </li>
        <li>
          <a href="#contact">
            Contact
            {isMobile ? null: <SquiggleCont />}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
