import React from "react";
import { Contact } from "../../components";
import { ReactComponent as FooterLogo } from "../../media/gabbie-logo_logo.svg";
import { useLocation } from "react-router-dom";
import "./index.scss";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <Contact />
      <footer>  
            <a href={location.pathname === "/" ? "#site-header":"/#site-header"}>
              <FooterLogo title="Gabbie Bade" className="footer-logo" />
            </a>
            <a href={location.pathname === "/" ? "#site-header":"/#site-header"}>
              <p className="copyright">
                &copy; {new Date().getFullYear()} Gabbie Bade
              </p>
            </a>
      </footer>
    </>
  );
};

export default Footer;
