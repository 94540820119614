const filterItems = [
  { label: "All", key: "all"},
  { label: "Development", key: "dev" },
  { label: "Design", key: "des"},
  { label: "PM & Marketing", key: "pmm"},
  { label: "Currently Learning", key: "curr", isChecked: false },
];

const Filters = ({ func, check }) => {
  return (
    <ul className="filter-box">
      {filterItems.map((f) => (
        <li key={f.key} onClick={func(f.key)} className={`filter-item ${check === f.key ? "check": ""}`}>{f.label}</li>
      ))}
    </ul>
  );
};
export default Filters;
