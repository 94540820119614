import React from 'react';
import { ReactComponent as LoadFace } from "../../media/gabbie_happy.svg";
import "./index.scss";

const Loading = () => {
  return (
    <div className="loading">
    <LoadFace className="loadface"/>
    <h2>Loading...</h2>
    </div>
  )
}

export default Loading;