import React from "react";
import "./index.scss";
import { useEffect, useState } from "react";
import { ReactComponent as FaceLeft } from "../../media/gabs-left.svg";
import { ReactComponent as FaceRight } from "../../media/gabs-right.svg";
import { ReactComponent as Face } from "../../media/gabs-mood.svg";
import { SkillsBasic, Loading } from "../../components";
import {motion} from "framer-motion";
import useIsMobile from "../../hooks/useIsMobile";

const About = () => {
  const restPath = "https://gabbiebade.com/XzgxVFWID/wp-json/wp/v2/pages/12";
  const [restData, setData] = useState([]);

  const [isLoaded, setLoadStatus] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);
  return (
    <motion.div
    className="about"
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      {isLoaded ? (
        <>
          <section id="about-gist">
            {isMobile ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: restData?.content?.rendered,
                  }}
                  className="wpAbout"
                ></div>
                <Face className="about-face" />
              </>
            ) : (
              <>
                <FaceLeft className="left-face" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: restData?.content?.rendered,
                  }}
                  className="wpAbout"
                ></div>
                <FaceRight className="right-face" />
              </>
            )}
          </section>
          <section id="toolkit">
            <h2>My Toolkit</h2>
            <SkillsBasic />
          </section>
        </>
      ) : (
        <Loading/>
      )}
    </motion.div>
  );
};

export default About;
