import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MainPhotoStored, WorkGrid, Loading } from "../../components";
import { motion } from "framer-motion";
import "./index.scss";

const Home = ({ featuredImage }) => {
  const restPath = "https://gabbiebade.com/XzgxVFWID/wp-json/wp/v2/pages/8";
  const [restData, setData] = useState([]);

  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  return (
    <motion.div
    className="home"
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      {isLoaded ? (
        <>
          <section className="mainHome">
            <h2>Gabbie Bade</h2>
            <MainPhotoStored />
            <section
              className="wpHome"
              dangerouslySetInnerHTML={{ __html: restData?.content?.rendered }}
            ></section>
            <div className="home-cta">
              <Link to={`/about`} className="primary-btn btn">
                Get to know me
              </Link>
              <Link to={`/work`} className="secondary-btn btn">
                See my work
              </Link>
            </div>
            <div id="break1"></div>
          </section>
          <section className="home-grid">
            <h2>Some projects</h2>
            <WorkGrid featuredImage={featuredImage} />
          </section>
        </>
      ) : (
        <Loading/>
      )}
    </motion.div>
  );
};

export default Home;
