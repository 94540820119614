import React from "react";
import { BrowserRouter } from "react-router-dom";
import AnimRoutes from "./AnimRoutes";
import "./styles/styles.scss";

const AppRouter = () => {
  return (
    <BrowserRouter basename="/">
      <AnimRoutes />
    </BrowserRouter>
  );
};

export default AppRouter;
