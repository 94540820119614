import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import workProxy from "../../media/work-placeholder.mp4";
import { ReactComponent as Arrow } from "../../media/iconmonstr-arrow-right-circle-filled.svg";
import "./index.scss";

const WorkGrid = ({ featuredImage }) => {
  const restPath =
    "https://gabbiebade.com/XzgxVFWID/wp-json/wp/v2/gabs-work?orderby=date&_embed";
  const [restData, setData] = useState([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, [restPath]);

  const page = useLocation();
  const current = page.pathname;
  const top3 = restData.slice(0, 3);
  let workArr;

  if (current === "/work") {
    workArr = restData;
  } else {
    workArr = top3;
  }

  return (
    <div className="work-gallery">
      {workArr.map((i) => (
        <article key={i.id} id={`post-${i.id}`} className="work-item">
          <Link to={`/work/${i.slug}`}>
            {i.featured_media ? (
              i._embedded["wp:featuredmedia"][0] && (
                <figure
                  className="featured-image"
                  dangerouslySetInnerHTML={featuredImage(
                    i._embedded["wp:featuredmedia"][0]
                  )}
                ></figure>
              )
            ) : (
              <figure className="featured-image">
                <video autoPlay muted playsInline loop src={workProxy} alt="gabbie bade work item" ></video>
              </figure>
            )}
            <h3>{i?.title?.rendered}</h3>
          </Link>
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: i?.excerpt?.rendered }}
          ></div>
        </article>
      ))}
      {current !== "/work" ? (
        isMobile ? (
          <div className="btn-container">
            <Link to={`/work`} className="primary-btn">View all</Link>
          </div>
        ) : (
          <>
            <Link to={`/work`} className="view-svg">
              <Arrow />
              <p>View all</p>
            </Link>
          </>
        )
      ) : null}
    </div>
  );
};

export default WorkGrid;
