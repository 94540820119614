import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useIsMobile from "../../hooks/useIsMobile";
import {motion} from "framer-motion"
import "./index.scss";

const Contact = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText("gabbiebade@gmail.com");
  };

  const isMobile = useIsMobile();
  return (
    <motion.div
    className="contact"
    initial={{opacity: 0}}
    whileInView={{opacity: 1}}
    >
    <section id="contact">
      <h2>Connect with me</h2>
      <ul className="socials">
        <li>
          <a
            href="https://www.linkedin.com/in/gabrielalorenbade/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
        </li>
        <li onClick={handleCopy} id="email">
          {isMobile ? (
            <a href="mailto:gabbiebade@gmail.com">
              <MailIcon />
            </a>
          ) : (
            <Tooltip title="Click to copy my email!">
              <Button variant="text">
                <MailIcon className="mailIcon" />
              </Button>
            </Tooltip>
          )}
        </li>
        <li>
          <a href="https://github.com/gbads" target="_blank" rel="noreferrer">
            <GitHubIcon />
          </a>
        </li>
      </ul>
    </section>
    </motion.div>
  );
};

export default Contact;
