import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {motion} from "framer-motion";
import { Loading } from "../../components";
import "./index.scss";

const SingleWork = ({ featuredImage }) => {
  let { workName } = useParams();
  const restPath = `https://gabbiebade.com/XzgxVFWID/wp-json/wp/v2/gabs-work?slug=${workName}&_embed`;
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data[0]);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  const acfArr = restData?.acf?.work_details;

  return (
    <motion.div
    className="single-work"
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      {isLoaded ? (
        <>
          <section id={`post-${restData?.id}`} className="work-content">
            <h2>{restData?.title?.rendered}</h2>
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: restData?.content?.rendered }}
            ></div>
            <div className="details-content">
              <h3>Project Details</h3>
              <div className="work-details">
                {acfArr.map((i, index) => (
                  <div key={index}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        className="acc-btn"
                      >
                        <Typography>{i.block_heading}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="panel">
                        <Typography>
                          <span
                            dangerouslySetInnerHTML={{ __html: i?.work_block }}
                          ></span>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
              <div className="btn-container">
                <Link to={`/work`} className="primary-btn">
                  View all
                </Link>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Loading/>
      )}
    </motion.div>
  );
};

export default SingleWork;
