import React from "react";
import Filters from "../Filters";
import Isotope from "isotope-layout";
import { useState, useRef } from "react";
import { skillsArr } from "../../globals/skillsArr";
import "./index.scss";

// Randomize skill items array. Reference: https://flaviocopes.com/how-to-shuffle-array-javascript/
const randSkills = skillsArr.sort(() => Math.random() - 0.5);

const SkillsBasic = () => {
  // Reference https://stackoverflow.com/questions/25135261/react-js-and-isotope-js
  const [filter, setFilter] = useState("all");
  

  const isotope = useRef();

  React.useEffect(() => {
    isotope.current = new Isotope(".skills-container", {
      itemSelector: ".one-skill",
      layoutMode: "fitRows",
      fitRows: {
        gutter: 2
      }
    });

    return () => isotope.current.destroy();
  }, []);

  React.useEffect(() => {
    filter === "*"
      ? isotope.current.arrange({ filter: `all` })
      : isotope.current.arrange({ filter: `.${filter}` });
  }, [filter]);

  const handleFilter = (key) => () => setFilter(key);

  return (
    <>
      <section className="skills-isotope">
        <Filters func={handleFilter} check={filter} />
        <div className="skills-container">
          <ul>
            {randSkills.map((card) => {
              return (
                <li key={card.id} className={`one-skill ${card.filter}`}>
                  {card.id}
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default SkillsBasic;
