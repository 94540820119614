import React from "react";
import { Link } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import { ReactComponent as Logo } from "../../media/gabbie-logo_logo.svg";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./index.scss";

const Header = ({ children }) => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const helmetContent = {
    "/": {
      title: "Gabbie Bade - Front End Web Developer",
      content:
        "Gabbie Bade's web development portfolio website featuring details about her and her recent projects.",
    },
    "/about": {
      title: "About Gabbie",
      content:
        "Know more about Gabbie's background, skills, and career history.",
    },
    "/work": {
      title: "Works",
      content:
        "Projects that Gabbie has worked on, her insights and processes as she works through development work.",
    },
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {
              helmetContent[pathname.includes("work") ? "/work" : pathname]
                .title
            }
          </title>
          <meta
            name="description"
            content={
              helmetContent[pathname.includes("work") ? "/work" : pathname]
                .content
            }
          />
        </Helmet>
      </HelmetProvider>
      <header
        id="site-header"
        className={isMobile ? "mobile-header" : "web-header"}
      >
        <div>
          <h1 id="site-brand">Gabbie Bade - Front End Web Developer</h1>
          <Link to="/">
            <Logo title="Gabbie Bade" />
          </Link>
        </div>
        {children}
      </header>
    </>
  );
};

export default Header;
