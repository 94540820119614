import React from "react";
import { ReactComponent as Meh } from "../../media/gabbie_meh.svg";
import { Link } from "react-router-dom";
import {motion} from "framer-motion";
import "./index.scss";

const NotFound = () => {
  return (
    <motion.div className="not-found" 
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
    >
      <Meh />
      <h2>Oops! Page not found.</h2>
      <Link to="/" className="primary-btn">
        Back to home
      </Link>
    </motion.div>
  );
};

export default NotFound;
