export const skillsArr = [
    {
      id: "Adobe Photoshop",
      filter: ["all des"],
    },
    {
      id: "HTML5",
      filter: ["all dev"],
    },
    {
      id: "Adobe XD",
      filter: ["all des"],
    },
    {
      id: "CSS",
      filter: ["all dev"],
    },
    {
      id: "SASS",
      filter: ["all dev"],
    },
    {
      id: "jQuery",
      filter: ["all dev"],
    },
    {
      id: "Wordpress",
      filter: ["all dev"],
    },
    {
      id: "Shopify",
      filter: ["all dev"],
    },
    {
      id: "Adobe Illustrator",
      filter: ["all des"],
    },
    {
      id: "Adobe Premiere",
      filter: ["all des"],
    },
    {
      id: "React",
      filter: ["all dev"],
    },
    {
      id: "Figma",
      filter: ["all des"],
    },
    {
      id: "Teamwork",
      filter: ["all pmm"],
    },
    {
      id: "Canva",
      filter: ["all des"],
    },
    {
      id: "Google Analytics",
      filter: ["all pmm"],
    },
    {
      id: "Asana",
      filter: ["all pmm"],
    },
    {
      id: "Trello",
      filter: ["all pmm"],
    },
    {
      id: "Angular",
      filter: ["all curr"],
    },
    {
      id: "Fitbit SDK",
      filter: ["all curr"],
    },
    {
      id: "Adobe Animate",
      filter: ["all curr"],
    },
    {
      id: "SVGator",
      filter: ["all curr"],
    },
  ];